import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";
import LatestArticles from "../components/latest_articles";
import RichAnchorArea from "../components/rich_anchor_area";
import RichAnchorItem from "../components/rich_anchor_item";
import { useStaticQuery } from "gatsby";

const IndexPage = ({ pageContext }) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
                externalReferences {
                    apiDocs,
                    customerPortal,
                    didimoShowcaseIOS,
                    unityStore,
                    unitySDK,
                    cliGitHub
                }
            }
        }
      }
    `
  );

  return (
    <Layout currentPath={pageContext.slug} mainMenuStructure={pageContext.mainMenuStructure}>
      <SEO title="Home" />
      <h1>Didimo Developer Portal</h1>
      <p>Explore our guides, examples and API documentation to create and use a didimo or integrate Didimo into your product.</p>
      <RichAnchorArea>
        <RichAnchorItem to="/guides" title="Generate my first didimo" />
        <RichAnchorItem to="/guides/unity-editor" title="Unity Didimo Editor" />
        <RichAnchorItem to="/guides/unity-sdk" title="Unity SDK" />
        <RichAnchorItem to={site.siteMetadata.externalReferences.apiDocs} title="API" />
      </RichAnchorArea>
      {/*<LatestArticles />*/}
      <hr />
      <p>Start using our applications and tools.</p>
      <RichAnchorArea>
        <RichAnchorItem to={site.siteMetadata.externalReferences.customerPortal} title="Customer Portal" />
        <RichAnchorItem to={site.siteMetadata.externalReferences.cliGitHub} title="Didimo CLI" />
        <RichAnchorItem to={site.siteMetadata.externalReferences.didimoShowcaseIOS} title="Didimo Showcase (iOS)" />
        <RichAnchorItem to={site.siteMetadata.externalReferences.unityStore} title="Unity Didimo Editor" />
        <RichAnchorItem to={site.siteMetadata.externalReferences.unitySDK} title="Unity SDK" />
      </RichAnchorArea>
      <hr />
      <p>Watch our tutorials.</p>
      <iframe width="100%" height="450" src="https://www.youtube.com/embed/67sR-dvdWps" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <hr />
      <p>For questions or technical support regarding anything in this documentation, please contact Didimo Support at <a href="mailto:support@didimo.co">support@didimo.co</a>.</p>
    </Layout>
  );
}

export default IndexPage
